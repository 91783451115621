import axios from 'axios';

// Cache for storing API responses
const cache = new Map();

// Queue for managing requests
class RequestQueue {
  constructor(interval = 100) {
    this.queue = [];
    this.interval = interval;
    this.processing = false;
  }

  async add(request) {
    return new Promise((resolve, reject) => {
      this.queue.push({ request, resolve, reject });
      this.process();
    });
  }

  async process() {
    if (this.processing) return;
    this.processing = true;

    while (this.queue.length > 0) {
      const { request, resolve, reject } = this.queue.shift();
      try {
        const result = await request();
        resolve(result);
      } catch (error) {
        reject(error);
      }
      await new Promise(resolve => setTimeout(resolve, this.interval));
    }

    this.processing = false;
  }
}

const requestQueue = new RequestQueue();

// Axios instance with retry logic
const vimeoClient = axios.create({
  timeout: 10000,
});

vimeoClient.interceptors.response.use(null, async (error) => {
  const { config } = error;
  config.retryCount = config.retryCount || 0;
  
  if (config.retryCount >= 2) {
    return Promise.reject(error);
  }
  
  config.retryCount += 1;
  
  // Exponential backoff
  const delay = Math.min(1000 * (2 ** config.retryCount), 5000);
  await new Promise(resolve => setTimeout(resolve, delay));
  
  return vimeoClient(config);
});

export const getVimeoOEmbed = async (videoId) => {
  // Check cache first
  const cacheKey = `vimeo-${videoId}`;
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  // Queue the request
  try {
    const response = await requestQueue.add(async () => {
      const apiUrl = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`;
      const { data } = await vimeoClient.get(apiUrl);
      return data;
    });

    // Cache the successful response
    cache.set(cacheKey, response);
    return response;
  } catch (error) {
    console.error(`Failed to fetch Vimeo data for video ${videoId}:`, error);
    return {
      error: true,
      message: error.message,
      fallback: true
    };
  }
};