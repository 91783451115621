import React from 'react';
import slugify from '@sentry/static-shared/utils/slugify';
import styled from '@emotion/styled';

import IconLink from '@sentry/static-shared/icons/link.svg';
import Button from '@sentry/static-shared/components/Button';

const extractTextFromReactElement = (element) => {
  let text = '';
  const traverse = (node) => {
    if (typeof node === 'string') {
      text += node;
    } else if (node && typeof node === 'object') {
      if (node.$$typeof) {
        // React element
        if (node.props && node.props.children) {
          traverse(node.props.children);
        }
      } else if (Array.isArray(node)) {
        // Array of nodes
        node.forEach(child => traverse(child));
      } else {
        console.error('HeadingSlugError: Unknown child', { element, node });
        // Other objects
        Object.values(node).forEach(value => traverse(value));
      }
    }
  }

  traverse(element);
  return text;
}

const SolutionJumpButton = () => (
  <StyledJumpButton
    className='btn-small'
    to="#the-solution"
    variant='secondary-light'
    redesign
  >
    Jump To Solution
  </StyledJumpButton>
)

const LinkedHeading = ({ size, children, solutionButtonEnabled, ...props }) => {
  const text = extractTextFromReactElement(children);
  const slug = slugify(text);
  return (
    <StyledHeading id={slug} as={size} {...props} className="scrollAnchor">
      <StyledLinkContainer>
        <StyledLink href={`#${props.id ? props.id : slug}`}>
          <StyledLinkIcon />
        </StyledLink>
        {children}
        {slug === 'the-problem' && solutionButtonEnabled && <SolutionJumpButton />}
      </StyledLinkContainer>
    </StyledHeading>
  );
};

const StyledLinkContainer = styled.div`
  position: relative;
`;

export default LinkedHeading;

const StyledLink = styled.a`
  position: absolute;
  padding-right: 0.25rem;
  margin-left: -1.25rem;
  left: 0;
  bottom: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  display: inline-flex;
  opacity: 0;

  &:focus {
    opacity: 1;
  }
`;

const StyledHeading = styled.h1`
  position: relative;
  &:hover ${StyledLink} {
    opacity: 1;
  }
  &:focus ${StyledLink} {
    opacity: 1;
  }
  
  &.scrollAnchor {
    scroll-margin-top: 4.5rem;
  }
`;

const StyledLinkIcon = styled(IconLink)`
  width: 1rem;
  height: 1rem;
`;

const StyledJumpButton = styled(Button)`
  margin-left: 1rem;

  &.btn-small {
    font-size: 0.8125rem;
    font-weight: 500;
    height: auto;
    line-height: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
`;
